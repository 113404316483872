import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { QuoteService } from './quote.service';
import {
    PRODUCTS,
    SERVICE_CARDS,
    AI_SERVICES,
    FAQS,
    TAILORED_SOL,
} from 'src/app/shared/constants/home';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BlogService } from 'src/app/blog/services/blog.service';
import { ContactUsServiceService } from '../ctas/contact-us-service.service';
import { AnnouncementService } from 'src/app/announcement/services/announcement.service';
import { Title, Meta } from '@angular/platform-browser';
declare var $: any;

interface TabContent {
    title: string;
    lead: string;
    description: string;
    details: string;
    buttonText: string;
    imageUrl: string;
}

// Define the type for the content object
interface Content {
    [key: string]: TabContent;
}

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['../../../app.common.scss', './home.component.scss'],
})
export class HomeComponent implements OnInit {
    quote: string | undefined;
    isLoading = false;
    displayPlatformContent = false;

    serviceCards = SERVICE_CARDS;
    products = PRODUCTS;
    aiServices = AI_SERVICES;
    faqs = FAQS;
    tailored = TAILORED_SOL;
    isJqueryWorking = '';
    anchorPlacement = 'bottom-bottom';

    activeProductIndex = 0;
    activeNav = 1;
    responsiveFade = 'fade-left';
    activeGif: string | undefined = '';

    showGif(gif: string, index: number) {
        this.activeProductIndex = index;
        const videoElement = document.getElementById('productVideo') as HTMLVideoElement;
        const sourceElement = document.getElementById('productVideoSource') as HTMLSourceElement;
        sourceElement.src = `${gif}`;
        videoElement.load();
    }

    @ViewChild('typeTarget', { static: true }) typeTarget!: ElementRef;

    constructor(
        private quoteService: QuoteService,
        deviceService: DeviceDetectorService,
        private blogService: BlogService,
        private contactUsService: ContactUsServiceService,
        private titleService: Title,
        private meta: Meta,
        private announcementService: AnnouncementService
    ) {
        if (deviceService.isMobile()) {
            this.anchorPlacement = 'top-bottom';
            this.responsiveFade = 'fade-up';
        }
        if (deviceService.isDesktop()) {
            this.anchorPlacement = 'top-bottom';
            this.responsiveFade = 'fade-up';
        }

        this.meta.updateTag({
            name: 'description',
            content:
                'Generative AI, Language Interfaces, Accelerators - Conversational AI, Search AI, RAG Knowledge Graphs, Multilingual, Multimodal, Voice Enabled AI Models and chatbots',
        });

        this.titleService.setTitle(
            'Genailia - Generative AI, Language Interfaces, Accelerators'
        );
    }

    navigateToSignup() {

        window.open('https://app.genailia.com/dashboard', '_blank');

    }
    relatedBlogs: any[] = [];
    annoucements: any[] = [];


    getRelatedBlogs() {
        this.blogService.getBlogPosts(undefined, false, undefined, 3, "random").subscribe(res => {
            console.log(res);
            this.relatedBlogs = res;
        })

    }

    getAnnoucements() {
        this.announcementService.getBlogPosts(undefined, false, undefined, undefined, "random").subscribe(res => {
            this.annoucements = res;
            console.log(res);
        })
    }

    logandsignup(email: string) {
        window.open('https://app.genailia.com', '_blank');
    }

    goToLink(url: string) {
        window.open(url, "_blank");
    }
    public mobile = false;
    ngOnInit() {
        this.isLoading = true;
        $(document).ready(() => {
            this.isJqueryWorking = 'Welcome To Genailia.com';
            console.log(this.isJqueryWorking);
        });


        if (window.screen.width >= 767) { // 768px portrait
            this.mobile = true;
        }
        else {
            this.mobile = false;
        }

        this.getRelatedBlogs();
        this.getAnnoucements();

    }


    selectedTab: string = 'conversational-ai';


    content: Content = {
        'conversational-ai': {
            title: 'Generative AI',
            lead: 'Engage with customers in real-time across channels',
            description: 'Our Conversational AI products provide seamless interactions with customers, powered by cutting-edge language models that understand and respond in a human-like manner.',
            details: 'These solutions are designed to enhance customer engagement, reduce response times, and improve overall satisfaction by offering 24/7 support.',
            buttonText: 'Learn More',
            imageUrl: '../../../../assets/images/conversational-ai.jpg'
        },
        'search-ai': {
            title: 'Search AI',
            lead: 'Unlock the power of data with intelligent search capabilities.',
            description: 'Search AI enables businesses to quickly find and retrieve information across vast datasets, improving efficiency and decision-making.',
            details: 'Whether it\'s internal knowledge bases or customer-facing search features, our Search AI solutions provide accurate, context-aware results that meet your specific needs.',
            buttonText: 'Discover More',
            imageUrl: '../../../../assets/images/search-ai.jpg'
        },
        'multilingual-ai': {
            title: 'Multilingual AI',
            lead: 'Break language barriers and reach a global audience.',
            description: 'Multilingual AI supports over 150 languages, offering translation, transcription, and text-to-speech services that cater to diverse audiences.',
            details: 'These tools ensure that your content is accessible to everyone, regardless of the language they speak, enhancing your global reach and engagement.',
            buttonText: 'See Details',
            imageUrl: '../../../../assets/images/multilingual-ai.jpg'
        },
        'experience-ai': {
            title: 'Experience AI',
            lead: 'Enhance customer, agent, and employee experiences.',
            description: 'Experience AI integrates with existing systems to provide personalized interactions and automate routine tasks, improving efficiency and satisfaction.',
            details: 'Our solutions are designed to make interactions smoother and more meaningful, whether it\'s through custom chatbots, personalized content, or automated workflows.',
            buttonText: 'Explore More',
            imageUrl: '../../../../assets/images/experience-ai.jpg'
        }
    };

    selectTab(tab: string) {
        this.selectedTab = tab;
    }

    getContent(tab: string): TabContent {
        return this.content[tab];
    }


    openContactUsForm() {
        this.contactUsService.showPopup();
    }

    formatToSlugWithGuid(input: string, guid: string): string {

        const slug = input
            .toLowerCase()
            .replace(/[^a-z0-9 ]/g, '')
            .trim()
            .replace(/\s+/g, '-');

        return `${slug}-${guid}`;
    }


    tablecards = [1, 2, 3]
    cards = [
        {
            iconClass: 'service-box_icon fa-solid fa-lock add2',
            title: 'Advanced Security & SSO',
            description: 'End-to-end encryption and SSL/TLS, integrating your organization\'s SSO ensures your data is secure at all times.',
        },
        {
            iconClass: 'service-box_icon fa-solid fa-cloud add2',
            title: 'Scalable Cloud Infrastructure',
            description: 'Our platform scales effortlessly with your business, providing robust and flexible cloud solutions.',
        },
        {
            iconClass: 'service-box_icon fa-solid fa-check-circle add2',
            title: 'Compliance & Governance',
            description: 'Adherence to industry standards and regulations, including GDPR and HIPAA, ensures compliance and peace of mind.',
        },
    ];



    tagCards = ['Model Hub', 'Prompt Studio', 'AI Agents', 'RLHF | RLAIF Evaluation Studio']

    logosrandom: string[] = [
        'assets/marquee/11.png',
        'assets/marquee/12.png',
        'assets/marquee/8.png',
        'assets/marquee/13.png',
        'assets/marquee/6.png',
        'assets/marquee/14.png',
        'assets/marquee/24.png',
        'assets/marquee/7.png',
        'assets/marquee/19.png',
        'assets/marquee/17.png',
        'assets/marquee/23.png',
        'assets/marquee/15.png',
        'assets/marquee/16.png',


        // Add more logos here
    ];
    logos: string[] = [
        'assets/marquee/6.png',
        'assets/marquee/7.png',
        'assets/marquee/8.png',
        'assets/marquee/11.png',
        'assets/marquee/12.png',
        'assets/marquee/13.png',
        'assets/marquee/14.png',
        'assets/marquee/15.png',
        'assets/marquee/16.png',
        'assets/marquee/17.png',
        'assets/marquee/19.png',
        'assets/marquee/23.png',
        'assets/marquee/24.png',


        // Add more logos here
    ];
    logos2: string[] = [
        'assets/img/28.png',
        'assets/img/29.png',
        'assets/img/30.png',
        'assets/img/31.png',
        'assets/img/25.png',
        'assets/img/26.png',
        'assets/img/27.png'
    ];

    logos3: string[] = [
        'assets/img/5.png',
        'assets/img/20.png',
        'assets/img/3.png',
        'assets/img/22.png',
        'assets/img/21.png',
    ];




    get reversedLogos(): string[] {
        return [...this.logosrandom];
    }

    get reversedLogos2(): string[] {
        return [...this.logos3].reverse(); // Create a new reversed array
    }

    HeroTags: any[] = [
        'Generative AI',
        'TTS',
        'Translation',
        'Transcription',
        'Transliteration',
        'OCR',
        '100+ Languages',
        'Live Speech Translation and Transcription',
        'Text and Spoken Language Detection',
        'Social Media Insights'
    ]
    HeroTagsNew: any = [
        'Generative AI',
        'Private LLM',
        'Multi-lingual Voice-enabled ChatBots',
        'LangChain',
        'LLaMaIndex',
        'Retrieval-Augmented Generation',
        'Vector DB',
        'NLP',
        'Images Generation',
        'Image Explanation'
    ]

    isFlipped: boolean[] = [];

    onMouseEnter(index: number) {
        this.isFlipped[index] = true;
    }

    onMoteuseLeave(index: number) {
        this.isFlipped[index] = false;
    }
    getKeys(obj: any): string[] {
        return Object.keys(obj);
    }
    tags = [
        { name: 'BankAssist'
        },
        { name: 'RetailAssist'
        },
        { name: 'HealthAssist'
        }
      ];

}
