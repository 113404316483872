<div class="container py-4 leadership">
	<div class="leadership-heading my-4">
		<div class="leadership-heading-hyphon"></div>
		<div class="leadership-heading-text">OUR</div>
		<div class="leadership-heading-ai-text">LEADERSHIP</div>
	</div>

	<div class="leadership-content">

		<p class="text-center">
			<b >See CEO Karthik's interview with SumanTV, a leading YouTube
				channel.</b>
		</p>
		<br />
		<div class="text-center ">
			<div class="responsive-iframe">
				<iframe width="914" height="514"
					src="https://www.youtube.com/embed/hGkUYZISBT8"
					title="Does every startup need funding? Genz Technologies Founder & CEO KARTHIK UPADRASTA | Suman iTTV"
					frameborder="0"
					allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
			</div>

		</div>

		<p><br/><br/>
			At <strong>Genailia</strong>, we believe that visionary leadership is
			the cornerstone of innovation. Our leadership team comprises industry
			pioneers and thought leaders with decades of experience in AI,
			technology, and business strategy. United by a shared passion for
			AI-driven transformation, our leaders inspire and guide our mission
			to redefine what's possible in the world of technology.
		</p>


		<p>
			<br />Each member of our leadership team brings a unique set of
			skills and perspectives to the table, ensuring that Genailia stays at
			the forefront of AI innovation. From shaping our product strategies
			to fostering a culture of creativity and excellence, our leaders are
			dedicated to driving the success of our clients and the growth of our
			company.
		</p>
		<p>
			<br />As we continue to push the boundaries of AI, our leadership
			team remains committed to ethical practices, continuous learning, and
			empowering the next generation of innovators. Together, we are not
			just leading a company - we are leading a movement that is
			transforming industries and enhancing lives through the power of AI.
		</p>
		<p>
			<br />Meet the minds behind Genailia and learn how their expertise
			and vision are driving us toward a future where AI is accessible,
			powerful, and transformative for businesses of all sizes.
		</p>
	</div>
</div>

<div class="container py-4 leadership">
	<div class="leadership-heading my-4">
		<div class="leadership-heading-hyphon"></div>
		<div class="leadership-heading-text">Key Members</div>
	</div>

	<div class="leadership-content">
		<div class="person">
			<img src="../../../../assets/img/leader1.png" width=200px
				alt="Leader 1" class="leader-image">
			<h2>Dr. Srinivas Reddy</h2>
			<p>Chief Data Scientist</p>
			<p>With a Ph.D. from IIIT Hyderabad, Dr. Srinivas leads our AI
				and data science initiatives. His work is the backbone of our
				innovative solutions, driving the development of intelligent systems
				that are as effective as they are groundbreaking.</p>
		</div>
		<div class="person">
			<h2>&nbsp;</h2>
			<p>&nbsp;</p>
		</div>

		<div class="person">
			<img src="../../../../assets/img/leader2.png" width=200px
				alt="Leader 1" class="leader-image">
			<h2>Karthik Upadrasta</h2>

			<p>Chief Executive Officer, Founder</p>
			<p>Karthik Upadrasta is a seasoned technology leader with over 15
				years of experience in the financial services industry. Holding both
				a Master's and Bachelor's degree in Computer Science, Karthik has
				built a robust career that spans across significant roles at Citi
				and Deutsche Bank.</p>
			<p>
				<br />At Citi, he rose to the level of Senior Vice President (SVP),
				where he led and managed teams of over 50 people, driving critical
				product development initiatives. Known for his entrepreneurial
				mindset within large organizations, Karthik played a pivotal role as
				an intrapreneur at Citi, consistently delivering innovative
				solutions that propelled business growth.
			</p>
			<p>
				<br />With his rich product development experience, Karthik brings a
				unique blend of technical expertise and strategic vision to the
				table, driving Genailia towards creating groundbreaking AI solutions
				that meet the evolving needs of businesses globally.
			</p>
		</div>
	</div>
</div>
